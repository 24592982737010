import { useEffect } from 'react';
import { legacyApi, useLazyGetUserBoxesQuery } from '../../../services/api.service';
import { useDispatch, useSelector } from 'react-redux';
import BottomSheet from '../../../components/BottomSheet';
import { useBoxSettingsBottomSheetLayoutProps } from '../../../layouts/BoxSettingsBottomSheetLayout';
import useConnectionLostGuard from '../../hooks/useConnectionLostGuard';
import { useLazyRefreshJwtQuery } from '../../../services/auth.service';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../store/rootReducer';
import {
  setBoxSettingsBottomSheetIsOpen,
  setPlayerBarIsOpen,
  setShutdownBoxModaleIsOpen,
} from '../../../store/modaleSlice';
import { Params, resetPlayerState } from '../../../store/playerSlice';
import { setIsSwitchingBox } from '../../../store/appSlice';
import { userIsLogged } from '../../../utils/security';

const BoxSettingsBottomSheet = () => {
  const appState = useSelector((state: RootState) => state.AppReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const [triggerGetUserBoxes, { data: boxes }] = useLazyGetUserBoxesQuery();
  const [refreshToken, { isFetching: refreshTokenFetching, isSuccess: refreshTokenSuccess }] = useLazyRefreshJwtQuery();
  const connectionLostGuard = useConnectionLostGuard();
  const boxSettingsBottomSheetLayoutPropsBuilder = useBoxSettingsBottomSheetLayoutProps();
  const boxSettingsBottomSheetIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.boxSettingsBottomSheetIsOpen,
  );
  const params: Params = useSelector((state: RootState) => state.PlayerReducer.params);
  useEffect(() => {
    if (boxSettingsBottomSheetIsOpen) {
      triggerGetUserBoxes();
    }
  }, [boxSettingsBottomSheetIsOpen, triggerGetUserBoxes]);

  useEffect(() => {
    if (appState.isSwitchingBox && !refreshTokenFetching && refreshTokenSuccess) {
      if (boxSettingsBottomSheetIsOpen) {
        dispatch(setBoxSettingsBottomSheetIsOpen(false));
      }
      dispatch(legacyApi.util.invalidateTags([{ type: 'boxDetails' }]));
    }
  }, [appState.isSwitchingBox, dispatch, boxSettingsBottomSheetIsOpen, refreshTokenFetching, refreshTokenSuccess]);

  return (
    <BottomSheet
      {...{
        isOpen: boxSettingsBottomSheetIsOpen,
        onDidDismiss: () => dispatch(setBoxSettingsBottomSheetIsOpen(false)),
      }}
      {...boxSettingsBottomSheetLayoutPropsBuilder({
        box: {
          id: appState.boxDetail?._id || '',
          name: appState.boxDetail?.name || '',
        },
        onBoxChangeLoading: refreshTokenFetching,
        username: appState.username || '',
        boxes,
        onLogoutButton: userIsLogged()
          ? () => {
              dispatch(setBoxSettingsBottomSheetIsOpen(false));
              setTimeout(() => {
                dispatchEvent(new Event('performLogout'));
              }, 300);
            }
          : undefined,
        onShutdownButton:
          appState.boxDetail?.player || !params.isTshokoConnected || !appState.boxDetail?.options.shutdown
            ? undefined
            : () => {
                connectionLostGuard(() => {
                  dispatch(setShutdownBoxModaleIsOpen(true));
                });
              },
        onBoxChange: (boxItem) => {
          dispatch(setIsSwitchingBox(true));
          dispatch(resetPlayerState());
          refreshToken({ boxId: boxItem.id });
          dispatch(setBoxSettingsBottomSheetIsOpen(false));
          dispatch(setPlayerBarIsOpen(false));
        },
        onShowCurrentMusicButton:
          !params.isTshokoConnected || params.tshokoStatus === 'stop'
            ? undefined
            : () => {
                connectionLostGuard(() => {
                  dispatch(setBoxSettingsBottomSheetIsOpen(false));
                  history.push('/app/admin');
                });
              },
      })}
    />
  );
};

export default BoxSettingsBottomSheet;
