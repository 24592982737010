import React from 'react';
import { IonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { StyledIonAlert } from './AlertModal.styled';

type IonAlertProps = React.ComponentProps<typeof IonAlert>;
export type AlertModalProps = IonAlertProps;

const AlertModal = (props: AlertModalProps) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <StyledIonAlert
      {...props}
      isOpen={isOpen}
      onDidDismiss={(e) => {
        setIsOpen(false);
        props.onDidDismiss?.(e);
      }}
      // wait alert to fade to execute handler
      buttons={props.buttons?.map((button) =>
        typeof button === 'string'
          ? button
          : {
              ...button,
              handler: (value) => {
                setTimeout(() => {
                  button.handler?.(value);
                }, 200);
              },
            },
      )}
    />
  );
};

export default AlertModal;
