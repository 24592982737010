import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PlaylistLayout from '../../layouts/Search/PlaylistLayout';
import { useGetPlaylistQuery } from '../../services/api.service';
import useConnectionLostGuard from '../hooks/useConnectionLostGuard';
import { getAmbianceFromQuerySelector } from '../Search/selector';
import store from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmPlayPlaylistModale, setTrackBottomSheet } from '../../store/modaleSlice';
import { useVoteTrackHook } from '../hooks/useVoteTrack';
import { RootState } from '../../store/rootReducer';

type PlaylistDrawerProps = {
  playlistId: string;
  independentPlaylist?: boolean;
  ambianceId?: string;
  ambianceColor?: string;
};

const PlaylistDrawer = (props: PlaylistDrawerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: playlist, isFetching } = useGetPlaylistQuery(`${props.playlistId}`);
  const connectionLostGuard = useConnectionLostGuard();
  const voteTrack = useVoteTrackHook();

  const ambianceQuery = props.ambianceId ? getAmbianceFromQuerySelector(store.getState())(props.ambianceId) : undefined;
  const isJukeboxModeOn = useSelector((state: RootState) => state.PlayerReducer.params.isJukeboxModeOn);

  const memoPlaylistLayout = useMemo(
    () =>
      playlist && !isFetching ? (
        <PlaylistLayout
          playlist={{
            ...playlist,
            source:
              playlist.soundDesignName && ambianceQuery
                ? `${playlist.soundDesignName + ' • '}${ambianceQuery.name}`
                : '',
            color: ambianceQuery?.color,
            title: playlist.name,
            creationDate: new Date(playlist.creationDate),
            tracks: [...playlist.musicsList]
              .sort((a, b) => a.order - b.order)
              .map((m, i) => ({
                id: m.trackId,
                artist: { id: 0, name: m.artist },
                img: m.img,
                title: m.title,
                explicit: false,
                number: i + 1,
                streamable: true,
              })),
          }}
          onPlayClick={() => {
            connectionLostGuard(() => {
              dispatch(setConfirmPlayPlaylistModale({ playlistId: props.playlistId }));
            });
          }}
          onClickTrack={(track) => dispatch(setTrackBottomSheet(track))}
          onClickTrackVote={isJukeboxModeOn ? (track) => voteTrack(track) : undefined}
        />
      ) : (
        <PlaylistLayout
          loading
          playlist={{
            color: props.ambianceColor,
            independent: props.independentPlaylist,
          }}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, playlist, t, isFetching],
  );
  return memoPlaylistLayout;
};
export default PlaylistDrawer;
