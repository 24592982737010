import React from 'react';
import { IonLabel, IonThumbnail, IonIcon, IonSkeletonText } from '@ionic/react';
import { ellipsisVertical, cloudDoneSharp } from 'ionicons/icons';
import {
  StyledIonItem,
  StyledTrackInfoContainer,
  StyledTrackTitleContainer,
  StyledTrackNumber,
  StyledTrackTitle,
  StyledEqualizerBars,
  StyledTimeContainer,
  StyledTrackSubTitle,
  DownloadIconContainer,
  StyledTrackLabelContainer,
  StyledTrackBlacklistedTag,
} from './TrackItem.styled';
import Explicit from '../Explicit';
import { fr } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import Typography from '../Typography';
import VoteButton from '../VoteButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { useTranslation } from 'react-i18next';

export type TrackItemDefaultProps = {
  track: {
    id: number;
    img: string;
    number?: number;
    title: string;
    explicit?: boolean;
    artist: {
      id: number;
      name: string;
    };
    blacklisted?: boolean;
    streamable: boolean;
    votes?: number;
    voted?: boolean;
  };
  onClick?: () => void;
  onVoteClick?: () => void;
  isDownloaded?: boolean;
  loading?: boolean;
  isPlaying?: boolean;
  playingDate?: Date;
  voteReadonly?: boolean;
};

type TrackItemLoading = { loading: true };

export type TrackItemProps = TrackItemDefaultProps | TrackItemLoading;

const EqualizerBars = () => (
  <StyledEqualizerBars>
    <div className="equalizer-bar" />
    <div className="equalizer-bar" />
    <div className="equalizer-bar" />
  </StyledEqualizerBars>
);

const TrackItem = (props: TrackItemProps) => {
  const { t } = useTranslation();
  const { isAdminMode } = useSelector((state: RootState) => state.AppReducer);

  const { loading } = props;
  if (loading) {
    return (
      <StyledIonItem className="track-item loading" mode="md">
        <IonThumbnail className="track-thumbnail" slot="start">
          <IonSkeletonText animated={true} style={{ borderRadius: 4 }} />
        </IonThumbnail>
        <IonLabel>
          <StyledTrackTitle>
            <Typography tag="h3" className="ion-text-nowrap" loading style={{ width: 150 }} />
          </StyledTrackTitle>
          <StyledTrackSubTitle>
            <Typography tag="h4" className="ion-text-nowrap" loading style={{ width: 100 }} />
          </StyledTrackSubTitle>
        </IonLabel>
        <div slot="end" style={{ width: 8 }} />
      </StyledIonItem>
    );
  }

  const { isPlaying, isDownloaded, track, voteReadonly, playingDate, onClick, onVoteClick } = props;

  const shouldBeShaded = (!isAdminMode && track.blacklisted) || !track.streamable;
  const itemClassName = `track-item${isPlaying ? ' is-playing' : ''}${shouldBeShaded ? ' is-blacklisted' : ''}${
    playingDate ? ' is-past' : ''
  }`;

  return (
    <>
      {
        <StyledIonItem className={itemClassName} button={!!onClick} onClick={onClick} mode="md">
          <IonThumbnail className="track-thumbnail" slot="start">
            <img src={track.img} />
            {isPlaying && <EqualizerBars />}
          </IonThumbnail>
          <IonLabel style={onVoteClick ? { paddingRight: 26 } : undefined}>
            <StyledTrackInfoContainer>
              {track.number && (
                <StyledTrackNumber>
                  <Typography tag="h3">{track.number}.</Typography>
                </StyledTrackNumber>
              )}
              <StyledTrackLabelContainer>
                <StyledTrackTitleContainer>
                  <StyledTrackTitle>
                    <Typography tag="h3" className="ion-text-nowrap">
                      {track.title}
                    </Typography>
                    {track.explicit && <Explicit />}
                  </StyledTrackTitle>
                  <StyledTrackSubTitle>
                    <Typography tag="h4" className="ion-text-nowrap">
                      {track.artist.name}
                    </Typography>
                    {playingDate && (
                      <StyledTimeContainer>
                        <Typography className="ion-text-nowrap">
                          {formatDistanceToNow(playingDate, {
                            addSuffix: true,
                            includeSeconds: false,
                            locale: fr,
                          })}
                        </Typography>
                      </StyledTimeContainer>
                    )}
                  </StyledTrackSubTitle>
                </StyledTrackTitleContainer>
              </StyledTrackLabelContainer>
              {track.blacklisted && <StyledTrackBlacklistedTag>{t('blacklistedTrack')}</StyledTrackBlacklistedTag>}
              {onVoteClick && (
                <div style={{ position: 'absolute', right: 42, top: 12 }}>
                  <VoteButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onVoteClick?.();
                    }}
                    voted={track.voted}
                    votes={track.votes}
                    readonly={voteReadonly || track.blacklisted || !track.streamable}
                  />
                </div>
              )}
            </StyledTrackInfoContainer>
          </IonLabel>
          {isDownloaded && !playingDate && !isPlaying && (
            <DownloadIconContainer>
              <IonIcon size="small" icon={cloudDoneSharp} color={'primary'} />
            </DownloadIconContainer>
          )}
          {!!onClick ? <IonIcon icon={ellipsisVertical} slot="end" /> : <div slot="end" style={{ width: 8 }} />}
        </StyledIonItem>
      }
    </>
  );
};

export default TrackItem;
