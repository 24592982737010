import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { PAGINATION_SIZE } from '../features/Search/selector';
import { TrackDto } from '../store/playerSlice';
import { getJwt } from '../utils/security';
import { Milliseconds } from '../utils/types';
import { TrackItemDefaultProps } from '../components/TrackItem';

export type SwitchableBox = {
  name: string;
  domain: string;
};

export type PlaylistDto = {
  id: string;
  name: string;
  updatedAt: string;
  duration: Milliseconds;
  ambiance?: { color: string };
  independent: boolean;
  musicsImagesSummary: [string?, string?, string?, string?];
};

export type PlaylistItemDto = {
  source: string;
  playlistItem: PlaylistDto;
};

export type ExtendedPlaylistDto = {
  id: string;
  name: string;
  creationDate: string;
  independent: boolean;
  soundDesignOwner: string;
  duration: number;
  musicsCount: number;
  updatedAt: string;
  musicsImagesSummary: [string?, string?, string?, string?];
  musicsList: TrackDto[];
  ambiance: string;
  ambianceName: string;
  soundDesignName: string;
};

export type AmbianceDto = {
  id: string;
  name: string;
  color: string;
  description: string;
  image: string;
  intensity: number;
  picto: string;
  playlistsCount: number;
  sampleDuration: number;
};

export type SpotDto = {
  id: string;
  duration: Milliseconds;
  name: string;
  label: string;
  image: string;
  author: string;
};

type SlotDto = {
  start: number;
  end: number;
  day: 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 is monday
} & (
  | {
      ambiance: {
        id: string;
        picto: string;
        name: string;
        color: string;
        image: string;
      };
    }
  | {
      playlist: {
        id: string;
        name: string;
        independent: boolean;
        musicsImagesSummary: [string?, string?, string?, string?];
        duration: number;
      };
    }
);

type BoxLiveDto = {
  currentOto: unknown; // not used
  nextDiffusion: unknown; // not used
  currentSlot: SlotDto;
  nextSlot: SlotDto;
};

export type AmbiancePlaylistsDto = {
  id: string;
  playlists: PlaylistDto[];
};

export type AmbianceDtoWithSoundDesignName = AmbianceDto & { soundDesignName: string };

export type SoundDesignDto = {
  id: string;
  name: string;
  ambiances: AmbianceDto[];
};

type BoxDto = {
  id: string;
  name: string;
  domain: string;
  client: string;
  locationAddress: string;
  tags: { id: string; color: string; name: string }[];
};

type BlacklistUpdateDto =
  | {
      type: 'track';
      clientId: string;
      trackId: number;
      title: string;
      artist: string;
      img: string;
    }
  | {
      type: 'album';
      clientId: string;
      albumId: string;
      title: string;
      artist: string;
      img: string;
    }
  | {
      type: 'artist';
      clientId: string;
      artistId: number;
      name: string;
      img: string;
    };

export type AggregatedBlacklistDto = {
  genres: number[];
  artists: number[];
  tracks: number[];
  albums: string[];
  keywords: string[];
  whitelistedArtists: number[];
  whitelistedAlbums: string[];
  whitelistedTracks: number[];
  popular: boolean;
  explicit: boolean;
};

export type ClientWithDom =
  | {
      success: true;
      result: {
        _id: string;
        name: string;
        domain: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        options: any;
        socket: {
          host: string;
          path: string;
        };
        email: string;
        player: boolean;
      };
    }
  | { success: false; msg: string };

// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_HOSTNAME}/api/v2`,
    prepareHeaders: (headers) => {
      const token = getJwt();
      if (token) {
        headers.set('Authorization', `JWT ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Playlist'],
  endpoints: (builder) => ({
    getBoxLive: builder.query<BoxLiveDto, string | undefined>({
      query: (boxId) => {
        if (!boxId) throw new Error('boxId is undefined');
        return { url: `boxes/${boxId}/live` };
      },
      keepUnusedDataFor: 60 * 60,
    }),
    getBlacklists: builder.query<AggregatedBlacklistDto, string>({
      query: (boxId) => ({ url: `aggregated/${boxId}/` }),
    }),
    getUserBoxes: builder.query<BoxDto[], void>({
      query: () => `/boxes`,
    }),
    getSpots: builder.query<SpotDto[], { search: string; limit: number; offset: number }>({
      query: (params) => ({ url: '/spots', params }),
      transformResponse: (response: SpotDto[]) => {
        return response.map((spot) => ({ ...spot, title: spot.name, img: spot.image }));
      },
    }),
    getUserPlaylists: builder.query<
      PlaylistItemDto[],
      {
        search: string;
        personnalUserPlaylistsOnly: boolean;
        offset: number;
        limit: number;
        sortBy: string;
        validOnly: boolean;
      }
    >({
      query: (params) => ({ url: `/availablePlaylists`, params }),
    }),
    getAllUserPlaylists: builder.query<PlaylistDto[], void>({
      query: () => ({ url: '/playlists' }),
      keepUnusedDataFor: 60 * 60,
    }),
    getPlaylist: builder.query<ExtendedPlaylistDto, string>({
      query: (playlistId) => ({ url: `/playlists/${playlistId}` }),
      keepUnusedDataFor: 60 * 60, // 1hour
      providesTags: ['Playlist'],
    }),
    getSoundDesigns: builder.query<SoundDesignDto[], void>({
      query: () => ({ url: `/soundDesigns?withAmbiances=true` }),
      keepUnusedDataFor: 60 * 60, // 1hour
    }),
    getAmbiancePlaylists: builder.query<AmbiancePlaylistsDto, { ambianceId: string; skip: number; limit: number }>({
      query: ({ ambianceId, ...params }) => ({ url: `/ambiance/${ambianceId}/playlists`, params }),
      keepUnusedDataFor: 60 * 60, // 1hour
    }),
    getIndependentPlaylists: builder.query<
      PlaylistDto[],
      {
        soundDesignId: string;
        sort: string;
        sortBy: string;
        skip: number;
        limit: number;
      }
    >({
      query: ({ soundDesignId, ...params }) => ({
        url: `/soundDesigns/${soundDesignId}/schedulable-playlists`,
        params,
      }),
      keepUnusedDataFor: 60 * 60, // 1hour
    }),
    getCurrentPlaylistTracks: builder.query<TrackItemDefaultProps['track'][], { boxId: string }>({
      query: ({ boxId }) => ({ url: `/box/${boxId}/currentPlaylist` }),
    }),
    getSoundDesignsWithIndependentPlaylists: builder.query<
      (SoundDesignDto & { independentPlaylists: PlaylistDto[] })[],
      void
    >({
      async queryFn(_arg, _queryApi) {
        const soundDesignsDtoQuery = (await _queryApi.dispatch(
          api.endpoints.getSoundDesigns.initiate(),
        )) as QueryReturnValue<SoundDesignDto[], FetchBaseQueryError, FetchBaseQueryMeta>;
        if (soundDesignsDtoQuery.error) return { error: soundDesignsDtoQuery.error as FetchBaseQueryError };
        const soundDesignsDto = soundDesignsDtoQuery.data;
        const independentPlaylistsQueries = await Promise.all(
          soundDesignsDto.map(async (soundDesign) => {
            const soundDesignIndependentPlaylistsQuery = (await _queryApi.dispatch(
              api.endpoints.getIndependentPlaylists.initiate({
                soundDesignId: soundDesign.id,
                sort: 'asc',
                sortBy: 'title',
                limit: PAGINATION_SIZE,
                skip: 0,
              }),
            )) as QueryReturnValue<PlaylistDto[], FetchBaseQueryError, FetchBaseQueryMeta>;
            return soundDesignIndependentPlaylistsQuery.data
              ? {
                  data: {
                    ...soundDesign,
                    independentPlaylists: soundDesignIndependentPlaylistsQuery.data as PlaylistDto[],
                  },
                }
              : { error: soundDesignIndependentPlaylistsQuery.error as FetchBaseQueryError };
          }),
        );
        const isError = independentPlaylistsQueries.find(
          (independentPlaylistsQuery) => independentPlaylistsQuery.error,
        );
        return !!isError
          ? { error: isError.error as FetchBaseQueryError }
          : {
              data: independentPlaylistsQueries.map(
                (independentPlaylistsQuery) => independentPlaylistsQuery.data,
              ) as (SoundDesignDto & { independentPlaylists: PlaylistDto[] })[],
            };
      },
    }),
    patchTrackToPlaylist: builder.mutation<undefined, { playlistIds: string[]; tracks: Partial<TrackDto>[] }>({
      query: (args) => ({ url: '/playlists/tracks', method: 'PATCH', body: args }),
      invalidatesTags: ['Playlist'],
    }),
  }),
});

export const legacyApi = createApi({
  reducerPath: 'legacyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_HOSTNAME}/api`,
    prepareHeaders: (headers) => {
      const token = getJwt();
      if (token) {
        headers.set('Authorization', `JWT ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['boxDetails'],
  endpoints: (builder) => ({
    getBoxWithDomain: builder.query<ClientWithDom, string>({
      providesTags: (res) => [{ type: 'boxDetails', id: (res?.success && res.result.domain) || 0 }],
      query: (domain) => ({ url: `/clientWithDom/${domain}`, headers: { Authorization: undefined } }),
    }),
    updateBlacklist: builder.mutation<unknown, BlacklistUpdateDto>({
      query: ({ type, ...options }) => ({ url: `blacklist/${type}`, body: options, method: 'post' }),
    }),
    getSwitchableBoxes: builder.query<SwitchableBox[], void>({
      query: () => ({ url: `/landing/fitnesspark` }),
    }),
  }),
});

export const {
  useLazyGetBlacklistsQuery,
  useGetBlacklistsQuery,
  useGetUserPlaylistsQuery,
  useLazyGetUserBoxesQuery,
  useGetUserBoxesQuery,
  useLazyGetUserPlaylistsQuery,
  useGetPlaylistQuery,
  useLazyGetAllUserPlaylistsQuery,
  useGetSoundDesignsQuery,
  useLazyGetSoundDesignsQuery,
  useLazyGetAmbiancePlaylistsQuery,
  useLazyGetSoundDesignsWithIndependentPlaylistsQuery,
  useLazyGetIndependentPlaylistsQuery,
  useLazyGetBoxLiveQuery,
  useLazyGetSpotsQuery,
  useGetBoxLiveQuery,
  usePatchTrackToPlaylistMutation,
  useGetCurrentPlaylistTracksQuery,
} = api;
export const {
  useLazyGetBoxWithDomainQuery,
  useGetBoxWithDomainQuery,
  useUpdateBlacklistMutation,
  useLazyGetSwitchableBoxesQuery,
} = legacyApi;
