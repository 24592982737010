import React, { Ref } from 'react';
import { IonSkeletonText, IonText } from '@ionic/react';
import { StyledIonText } from './Typography.styled';
import { getTypographyBoundingClientRect } from '../../utils/dom';
type IonTypographyProps = React.ComponentProps<typeof IonText>;
export type TypographyProps = IonTypographyProps & {
  tag?: keyof HTMLElementTagNameMap;
  loading?: boolean;
  lines?: number;
  variant?: 'primary' | 'secondary';
  innerRef?: Ref<HTMLIonTextElement>;
};

const Typography = (props: TypographyProps) => {
  const { children, tag, className, ...rest } = props;
  const tagType = tag || React.Fragment;

  // eslint-disable-next-line @typescript-eslint/dot-notation
  let LoadingText;
  if (props.loading) {
    const lorem = getTypographyBoundingClientRect(tag, props.children?.toString());
    LoadingText = () =>
      React.createElement(
        tagType,
        tag
          ? { className: `${className || ''} ${props.variant === 'secondary' ? 'text-secondary' : 'text-primary'}` }
          : {},
        <>
          {
            <>
              {tagType === 'p' || tagType === React.Fragment ? (
                <>
                  {Array.from({ length: props.lines || 4 }).map((e, i) => (
                    <IonSkeletonText
                      key={`p-skeleton-${i}`}
                      animated={true}
                      style={{ height: lorem.height - 3, maxheight: '100%', maxWidth: '100%' }}
                    />
                  ))}
                  {props.lines !== 1 && (
                    <IonSkeletonText
                      key={`p-skeleton-${props.lines || 4}`}
                      animated={true}
                      style={{ height: lorem.height - 3, width: '50%', maxheight: '100%', maxWidth: '100%' }}
                    />
                  )}
                </>
              ) : (
                <IonSkeletonText
                  animated={true}
                  style={{ height: lorem.height - 3, maxheight: '100%', width: lorem.width, maxWidth: '100%' }}
                />
              )}
            </>
          }
        </>,
      );
  }

  const Text = () =>
    React.createElement(
      tagType,
      tag
        ? { className: `${className || ''} ${props.variant === 'secondary' ? 'text-secondary' : 'text-primary'}` }
        : {},
      <>{children}</>,
    );
  return (
    <StyledIonText
      className={`${className ? className : ''} ${props.variant === 'secondary' ? 'text-secondary' : 'text-primary'}`}
      ref={props.innerRef}
      {...rest}
    >
      {props.loading && LoadingText ? <LoadingText /> : <Text />}
    </StyledIonText>
  );
};

export default Typography;
