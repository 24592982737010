import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BottomSheet, { BottomSheetProps } from '../../components/BottomSheet';
import Typography from '../../components/Typography';
import { Without } from '../../utils/types';
import { IonIcon, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { StyledHeaderBoxSettings, StyledIonItem } from './BoxSettingsBottomSheetLayout.styled';
import { logOutOutline, power, swapHorizontal } from 'ionicons/icons';
import CurrentMusicIcon from '../../components/Icons/CurrentMusicIcon';
import Avatar from '../../components/Avatar';
import Spinner from '../../components/Spinner';
import { isAdminApp } from '../../utils/platform';

export type BoxSettingsBottomSheetLayoutProps = Without<BottomSheetProps, 'onDidDismiss' | 'isOpen'>;

type BoxItem = { id: string; name: string };

type BoxSettingsProps = {
  username: string;
  box: BoxItem;
  boxes?: BoxItem[];
  onBoxChange?: ({ id, name }: BoxItem) => void;
  onBoxChangeLoading?: boolean;
  onLogoutButton?: () => void;
  onShowCurrentMusicButton?: () => void;
  onShutdownButton?: () => void;
};

export const useBoxSettingsBottomSheetLayoutProps = () => {
  const { t } = useTranslation();
  const boxesSelectRef = useRef<HTMLIonSelectElement>(null);

  const boxSettingsBottomSheetLayoutBuilder = (props: BoxSettingsProps) => {
    const displayedBoxes = [props.box, ...(props.boxes || []).filter((box) => box.id !== props.box.id)].sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    return {
      header: (
        <StyledHeaderBoxSettings>
          <Avatar />
          <div className="info">
            <Typography className="username" tag="h3">
              {props.username}
            </Typography>
            <Typography className="box-name" tag="h4" variant="secondary">
              {props.box.name}
            </Typography>
          </div>
        </StyledHeaderBoxSettings>
      ),
      content: (
        <div>
          {displayedBoxes.length > 1 && isAdminApp() && props.onBoxChange && (
            <StyledIonItem
              disabled={props.onBoxChangeLoading}
              button
              mode="md"
              onClick={() => boxesSelectRef.current?.open()}
            >
              <div className="menu-item">
                {props.onBoxChangeLoading ? <Spinner /> : <IonIcon icon={swapHorizontal} />}
                <IonLabel>{t('changeBox')}</IonLabel>
              </div>
              <IonSelect
                style={{ display: 'none' }}
                label={t('changeBox')}
                mode="ios"
                ref={boxesSelectRef}
                okText={t('change')}
                cancelText={t('cancel')}
                value={props.box.id}
                onIonChange={(e) => {
                  if (e.detail.value !== props.box.id) {
                    props.onBoxChange?.(displayedBoxes?.find((box) => box.id === e.detail.value) as BoxItem);
                  }
                }}
              >
                {displayedBoxes.map((box) => (
                  <IonSelectOption value={box.id} key={`select-item-box-${box.id}`}>
                    {box.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </StyledIonItem>
          )}
          {props.onLogoutButton && (
            <StyledIonItem button mode="md" onClick={() => props.onLogoutButton?.()}>
              <div className="menu-item">
                <IonIcon icon={logOutOutline} />
                <IonLabel>{t('logout')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
          {props.onShutdownButton && (
            <StyledIonItem button mode="md" onClick={() => props.onShutdownButton?.()}>
              <div className="menu-item">
                <IonIcon icon={power} />
                <IonLabel>{t('shutdownBox')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
          {props.onShowCurrentMusicButton && (
            <StyledIonItem button mode="md" onClick={() => props.onShowCurrentMusicButton?.()}>
              <div className="menu-item">
                <CurrentMusicIcon />
                <IonLabel>{t('handleCurrentMusic')}</IonLabel>
              </div>
            </StyledIonItem>
          )}
        </div>
      ),
      initialBreakpoint:
        Math.ceil(
          ((196 +
            (displayedBoxes.length > 1 && props.onBoxChange ? 48 : 0) +
            (props.onShutdownButton ? 48 : 0) +
            (props.onShowCurrentMusicButton ? 48 : 0)) *
            100) /
            window.innerHeight,
        ) / 100,
    };
  };
  return boxSettingsBottomSheetLayoutBuilder;
};

const BoxSettingsBottomSheetLayout = (
  props: Without<BottomSheetProps, keyof BoxSettingsBottomSheetLayoutProps> & BoxSettingsProps,
) => {
  const boxSettingsBottomSheetLayoutPropsBuilder = useBoxSettingsBottomSheetLayoutProps();
  const {
    username,
    box,
    boxes,
    onBoxChange,
    onBoxChangeLoading,
    onLogoutButton,
    onShowCurrentMusicButton,
    onShutdownButton,
    ...bottomSheetProps
  } = props;
  return (
    <BottomSheet
      {...bottomSheetProps}
      {...boxSettingsBottomSheetLayoutPropsBuilder({
        username,
        box,
        onBoxChangeLoading,
        boxes,
        onBoxChange,
        onLogoutButton,
        onShowCurrentMusicButton,
        onShutdownButton,
      })}
    />
  );
};

export default BoxSettingsBottomSheetLayout;
