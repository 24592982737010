import { useRef, useState } from 'react';
import { IonThumbnail } from '@ionic/react';
import { playSkipForward, shuffle } from 'ionicons/icons';
import {
  StyledIonItem,
  StyledPlayerBarContainer,
  StyledIonLabel,
  StyledCurrentTitle,
  StyledCurrentArtist,
} from './PlayerBar.styled';
import Explicit from '../Explicit';
import IconButton from '../IconButton';
import PlayerProgressBar from '../PlayerProgressBar';
import { CurrentTrack } from '../../store/playerSlice';
import { differenceInMilliseconds } from 'date-fns';
import useResizeObserver from '@react-hook/resize-observer';
import SpotIcon from '../Icons/SpotIcon';
import { isSpot } from '../../utils/typeGuards';
import { spotImageSourceToCdnUrl } from '../../utils/file';

export type PlayerBarProps = {
  onSkipForward?: () => void;
  track?: CurrentTrack;
  shuffle?: boolean;
  onShuffleButton?: () => void;
};

type Widths = { scrollWidth: number; clientWidth: number };
const isOverflown = ({ clientWidth, scrollWidth }: Widths) => scrollWidth > clientWidth;

const PlayerBar = (props: PlayerBarProps) => {
  const [widthsTitle, setWidthsTitle] = useState<Widths>({
    scrollWidth: 0,
    clientWidth: 0,
  });
  const [widthsArtist, setWidthsArtist] = useState<Widths>({
    scrollWidth: 0,
    clientWidth: 0,
  });
  const titleDivRef = useRef<HTMLDivElement>(null);
  const artistDivRef = useRef<HTMLDivElement>(null);

  const titleOverflown = isOverflown(widthsTitle);
  const artistOverflown = isOverflown(widthsArtist);

  useResizeObserver(titleDivRef, (entry) => {
    setWidthsTitle({
      scrollWidth: entry.target.scrollWidth,
      clientWidth: entry.target.clientWidth,
    });
  });
  useResizeObserver(artistDivRef, (entry) => {
    setWidthsArtist({
      scrollWidth: entry.target.scrollWidth,
      clientWidth: entry.target.clientWidth,
    });
  });

  return (
    <StyledIonItem className="player-item" mode="md">
      <IonThumbnail className="player-thumbnail" slot="start">
        {isSpot(props.track) ? (
          <div className="spot">
            {props.track.img ? (
              <img src={spotImageSourceToCdnUrl(props.track.img)} style={{ borderRadius: 4 }} />
            ) : (
              <SpotIcon />
            )}
          </div>
        ) : props.track?.img ? (
          <img src={props.track.img} />
        ) : null}
      </IonThumbnail>
      <StyledIonLabel>
        <StyledCurrentTitle widths={widthsTitle}>
          <h3 className={`title ion-text-nowrap ${titleOverflown ? 'overflown' : ''}`}>
            <div ref={titleDivRef}>{props.track?.title}</div>
          </h3>
          {props.track?.explicit && <Explicit />}
        </StyledCurrentTitle>
        <StyledCurrentArtist widths={widthsArtist}>
          <h4 className={`artist ion-text-nowrap ${artistOverflown ? 'overflown' : ''}`}>
            <div ref={artistDivRef}>{props.track?.artist.name}</div>
          </h4>
        </StyledCurrentArtist>
      </StyledIonLabel>
      {props.onShuffleButton && (
        <IconButton
          icon={shuffle}
          color={props.shuffle ? 'primary' : undefined}
          onClick={(e) => {
            e.stopPropagation();
            props.onShuffleButton?.();
          }}
        />
      )}
      {props.onSkipForward && (
        <IconButton
          icon={playSkipForward}
          onClick={(e) => {
            e.stopPropagation();
            props.onSkipForward?.();
          }}
        />
      )}

      <PlayerProgressBar
        timer={differenceInMilliseconds(
          new Date(),
          props.track?.playingDate ? new Date(props.track.playingDate) : new Date(),
        )}
        duration={props.track?.duration || 1}
      />
    </StyledIonItem>
  );
};

export const PlayerBarContainer = (props: PlayerBarProps) => {
  return (
    <StyledPlayerBarContainer id="player">
      <PlayerBar {...props} />
    </StyledPlayerBarContainer>
  );
};

export default PlayerBar;
