import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */

type SearchState = {
  currentSearch: string;
  albumsOffset: number;
  artistsOffset: number;
  playlistsOffset: number;
  tracksOffset: number;
  spotsOffset: number;
};

const initialSearchSlice: SearchState = {
  currentSearch: '',
  albumsOffset: 0,
  artistsOffset: 0,
  tracksOffset: 0,
  playlistsOffset: 0,
  spotsOffset: 0,
};

const SearchSlice = createSlice({
  name: 'SearchSlice',
  initialState: initialSearchSlice,
  reducers: {
    search: (state: SearchState, action: PayloadAction<string>) => {
      return { ...initialSearchSlice, currentSearch: action.payload };
    },
    fetchMoreTracks: (state: SearchState, action: PayloadAction<number>) => {
      return { ...state, tracksOffset: action.payload };
    },
    fetchMoreAlbums: (state: SearchState, action: PayloadAction<number>) => {
      return { ...state, albumsOffset: action.payload };
    },
    fetchMorePlaylists: (state: SearchState, action: PayloadAction<number>) => {
      return { ...state, artistsOffset: action.payload };
    },
    fetchMoreArtists: (state: SearchState, action: PayloadAction<number>) => {
      return { ...state, artistsOffset: action.payload };
    },
    fetchMoreSpots: (state: SearchState, action: PayloadAction<number>) => {
      return { ...state, artistsOffset: action.payload };
    },
  },
});

const DrawerReducer = SearchSlice.reducer;

export const { search, fetchMoreTracks, fetchMoreAlbums, fetchMorePlaylists, fetchMoreArtists, fetchMoreSpots } =
  SearchSlice.actions;
export default DrawerReducer;
