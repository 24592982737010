import { api, PlaylistItemDto, AmbianceDtoWithSoundDesignName, SpotDto } from '../../services/api.service';
import {
  TracksSearchResult,
  qobuz,
  ArtistsSearchResult,
  AlbumsSearchResult,
  QobuzTrackDto,
} from '../../services/qobuz.service';
import { RootState } from '../../store/rootReducer';
export const PAGINATION_SIZE = 30;
export const PAGINATION_MAX = 8;

export const isQobuzTrackDtoGuard = (track: unknown): track is QobuzTrackDto => {
  return (
    typeof track === 'object' && track !== null && 'isrc' in track && 'album' in track && 'parental_warning' in track
  );
};

// TODO : memoize this selector if possible ?
export const getAllSearchedTracksSelector = (state: RootState) => {
  const allTracks: QobuzTrackDto[] = [];
  Object.keys(state.qobuz.queries).map((key) => {
    const data = state.qobuz.queries[key]?.data;
    if (
      data &&
      typeof data === 'object' &&
      'tracks' in data &&
      typeof data.tracks === 'object' &&
      data.tracks &&
      'items' in data.tracks &&
      typeof data.tracks.items === 'object' &&
      Array.isArray(data.tracks.items)
    ) {
      data.tracks.items
        .map((t) => {
          if (key.includes('getAlbum')) {
            return { ...t, album: data };
          } else {
            return t;
          }
        })
        .filter((t) => isQobuzTrackDtoGuard(t))
        .forEach((t) => {
          allTracks.push(t);
        });
    }
  });
  return allTracks;
};

export const getAllTracksFromQuerySelector = (state: RootState) => (query: string) => {
  let offsetReached = false;
  let offset = 0;
  const limit = PAGINATION_SIZE;
  const globalData = qobuz.endpoints.globalSearch.select({ query })(state).data;
  offset += globalData?.tracks.items.length || 0;
  let allResults: TracksSearchResult = {
    tracks: {
      items: globalData?.tracks.items || [],
      limit: PAGINATION_SIZE + (globalData?.tracks.limit || 0),
      offset: 0,
      total: globalData?.tracks.total || 0,
    },
    query: query,
  };
  while (offsetReached === false && offset < PAGINATION_MAX * PAGINATION_SIZE) {
    const data = qobuz.endpoints.tracksSearch.select({ query, limit, offset })(state).data;
    offset += PAGINATION_SIZE;
    if (data) {
      allResults = {
        tracks: {
          items: [...allResults.tracks.items, ...data.tracks.items],
          limit: allResults.tracks.limit + PAGINATION_SIZE,
          offset: 0,
          total: data.tracks.total,
        },
        query,
      };
    } else {
      offsetReached = true;
    }
  }
  return allResults;
};

export const getAllArtistsFromQuerySelector = (state: RootState) => (query: string) => {
  let offsetReached = false;
  let offset = 0;
  const limit = PAGINATION_SIZE;
  const globalData = qobuz.endpoints.globalSearch.select({ query })(state).data;
  offset += globalData?.artists.items.length || 0;
  let allResults: ArtistsSearchResult = {
    artists: {
      items: globalData?.artists.items || [],
      limit: PAGINATION_SIZE + (globalData?.artists.limit || 0),
      offset: 0,
      total: globalData?.artists.total || 0,
    },
    query: query,
  };
  while (offsetReached === false && offset < PAGINATION_MAX * PAGINATION_SIZE) {
    const data = qobuz.endpoints.artistsSearch.select({ query, limit, offset })(state).data;
    offset += PAGINATION_SIZE;
    if (data) {
      allResults = {
        artists: {
          items: [...allResults.artists.items, ...data.artists.items],
          limit: allResults.artists.limit + PAGINATION_SIZE,
          offset: 0,
          total: data.artists.total,
        },
        query,
      };
    } else {
      offsetReached = true;
    }
  }
  return allResults;
};

export const getAllAlbumsFromQuerySelector = (state: RootState) => (query: string) => {
  let offsetReached = false;
  let offset = 0;
  const limit = PAGINATION_SIZE;
  const globalData = qobuz.endpoints.globalSearch.select({ query })(state).data;
  offset += globalData?.albums.items.length || 0;
  let allResults: AlbumsSearchResult = {
    albums: {
      items: globalData?.albums.items || [],
      limit: PAGINATION_SIZE + (globalData?.albums.limit || 0),
      offset: 0,
      total: globalData?.albums.total || 0,
    },
    query: query,
  };
  while (offsetReached === false && offset < PAGINATION_MAX * PAGINATION_SIZE) {
    const data = qobuz.endpoints.albumsSearch.select({ query, limit, offset })(state).data;
    offset += PAGINATION_SIZE;
    if (data) {
      allResults = {
        albums: {
          items: [...allResults.albums.items, ...data.albums.items],
          limit: allResults.albums.limit + PAGINATION_SIZE,
          offset: 0,
          total: data.albums.total,
        },
        query,
      };
    } else {
      offsetReached = true;
    }
  }
  return allResults;
};
export const getAllPlaylistsFromQuerySelector = (state: RootState) => (search: string) => {
  let offsetReached = false;
  let offset = 0;
  const limit = PAGINATION_SIZE;
  let allResults: PlaylistItemDto[] = [];
  while (offsetReached === false && offset < PAGINATION_MAX * PAGINATION_SIZE) {
    const data = api.endpoints.getUserPlaylists.select({
      search,
      limit,
      offset,
      personnalUserPlaylistsOnly: true,
      validOnly: true,
      sortBy: 'creationDate',
    })(state).data;
    offset += PAGINATION_SIZE;
    if (data) {
      allResults = [...allResults, ...data];
    } else {
      offsetReached = true;
    }
  }
  return allResults;
};
export const getAllSpotsFromQuerySelector = (state: RootState) => (search: string) => {
  let offsetReached = false;
  let offset = 0;
  const limit = PAGINATION_SIZE;
  let allResults: SpotDto[] = [];
  while (offsetReached === false && offset < PAGINATION_MAX * PAGINATION_SIZE) {
    const data = api.endpoints.getSpots.select({
      search,
      limit,
      offset,
    })(state).data;
    offset += PAGINATION_SIZE;
    if (data) {
      allResults = [...allResults, ...data];
    } else {
      offsetReached = true;
    }
  }
  return allResults;
};
export const getAllAmbiancesFromQuerySelector = (state: RootState) => (search: string) => {
  const fiteredAmbiances = (api.endpoints.getSoundDesigns.select()(state).data || [])
    .map((soundDesignDto) => ({
      ...soundDesignDto,
      ambiances: soundDesignDto.ambiances.map((ambiance) => ({
        ...ambiance,
        image: `${process.env.REACT_APP_CDN_HOSTNAME}${ambiance.image}`,
        soundDesignName: soundDesignDto.name,
      })),
    }))
    .reduce(
      (ambiancesDto, soundDesignDtoWithSoundDesignName) => [
        ...ambiancesDto,
        ...soundDesignDtoWithSoundDesignName.ambiances,
      ],
      [] as AmbianceDtoWithSoundDesignName[],
    )
    .filter((ambianceDto) => ambianceDto.name.toLowerCase().includes(search.toLowerCase()));
  return fiteredAmbiances;
};
export const getAmbianceFromQuerySelector = (state: RootState) => (ambianceId: string) => {
  const ambiance = (api.endpoints.getSoundDesigns.select()(state).data || [])
    .map((soundDesignDto) => ({
      ...soundDesignDto,
      ambiances: soundDesignDto.ambiances.map((a) => ({
        ...a,
        image: `${process.env.REACT_APP_CDN_HOSTNAME}${a.image}`,
        soundDesignName: soundDesignDto.name,
      })),
    }))
    .reduce(
      (ambiancesDto, soundDesignDtoWithSoundDesignName) => [
        ...ambiancesDto,
        ...soundDesignDtoWithSoundDesignName.ambiances,
      ],
      [] as AmbianceDtoWithSoundDesignName[],
    )
    .find((ambianceDto) => ambianceDto.id === ambianceId);
  return ambiance;
};

export const getAllSoundDesignDtoWithIndependentPlaylistsFromQuerySelector = (state: RootState) => () => {
  const allSoundDesignDtoWithIndependentPlaylists = (api.endpoints.getSoundDesigns.select()(state).data || []).map(
    (soundDesignDto) => ({
      ...soundDesignDto,
      ambiances: soundDesignDto.ambiances.map((ambiance) => ({
        ...ambiance,
        image: `${process.env.REACT_APP_CDN_HOSTNAME}${ambiance.image}`,
      })),
      independentPlaylists:
        api.endpoints.getIndependentPlaylists.select({
          soundDesignId: soundDesignDto.id,
          sort: 'asc',
          sortBy: 'title',
          limit: PAGINATION_SIZE,
          skip: 0,
        })(state).data || [],
    }),
  );
  return allSoundDesignDtoWithIndependentPlaylists;
};

export const getSoundDesignDtoWithIndependentPlaylistsFromQuerySelector =
  (state: RootState) => (soundDesignId: string) => {
    const soundDesignDtoWithIndependentPlaylists = (api.endpoints.getSoundDesigns.select()(state).data || [])
      .map((soundDesignDto) => ({
        ...soundDesignDto,
        independentPlaylists:
          api.endpoints.getIndependentPlaylists.select({
            soundDesignId: soundDesignDto.id,
            sort: 'asc',
            sortBy: 'title',
            limit: PAGINATION_SIZE,
            skip: 0,
          })(state).data || [],
      }))
      .find((soundDesignDto) => soundDesignDto.id === soundDesignId);
    return soundDesignDtoWithIndependentPlaylists;
  };
