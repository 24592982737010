import React from 'react';
import { IonContent } from '@ionic/react';
import AlbumCard from '../../../../components/AlbumCard';
import List from '../../../../components/List';
import ArtistCard from '../../../../components/ArtistCard';
import InfiniteVirtualScroll from '../../../../components/InfiniteVirtualScroll';
import PlaylistCard from '../../../../components/PlaylistCard';
import TrackItem, { TrackItemDefaultProps } from '../../../../components/TrackItem';
import { useTranslation } from 'react-i18next';
import { SearchLayoutProps } from '../SearchLayout';
import Typography from '../../../../components/Typography';
import AmbianceCard from '../../../../components/AmbianceCard';
import ArtistDrawer from '../../../../features/Drawer/ArtistDrawer';
import AlbumDrawer from '../../../../features/Drawer/AlbumDrawer';
import PlaylistDrawer from '../../../../features/Drawer/PlaylistDrawer';
import AmbianceDrawer from '../../../../features/Drawer/AmbianceDrawer';
import { sharpen } from '../../../../utils/number';
import useKeyboard from '../../../../utils/hooks/useKeyboard';
import { StyledIonNavLink } from '../../../../features/Drawer/Drawer.styled';
import SpotCard, { SpotCardDefaultProps } from '../../../../components/SpotCard';
import { useFeatureAvailable } from '../../../../utils/hooks/useFeatureAvailable';

const SearchTabMostPopular = (props: {
  loading?: boolean;
  adminMode?: boolean;
  searchResults: SearchLayoutProps['searchResults'];
  onClickTrack: (track: TrackItemDefaultProps['track']) => void;
  onClickTrackVote?: (track: TrackItemDefaultProps['track']) => void;
  onClickTracks: () => void;
  onClickArtists: () => void;
  isArtistsFetching?: boolean;
  loadDataArtists?: () => void;
  onClickAlbums: () => void;
  isAlbumsFetching?: boolean;
  loadDataAlbums?: () => void;
  onClickPlaylists: () => void;
  isPlaylistsFetching?: boolean;
  loadDataPlaylists?: () => void;
  onClickAmbiances: () => void;
  isAmbiancesFetching?: boolean;
  onClickSpotCard: (spot: SpotCardDefaultProps['spot']) => void;
  onClickSpots: () => void;
  isSpotsFetching?: boolean;
  loadDataSpots?: () => void;
}) => {
  const { searchResults } = props;
  const { t } = useTranslation();
  const { hideKeyboard } = useKeyboard();
  const nbItemsPerViewMostPopular = sharpen(window.innerWidth / (320 / 2));
  const playSpotFeatureAvailable = useFeatureAvailable('playSpotFeature');

  return searchResults ? (
    <IonContent scrollEvents onIonScrollStart={() => hideKeyboard?.()}>
      <div style={props.loading ? { pointerEvents: 'none' } : undefined}>
        <List header={t('mostPopular')}>
          {props.loading ? (
            <div style={{ width: 150 }}>
              <ArtistCard key={`most-popular-loading`} loading />
            </div>
          ) : searchResults.mostPopular?.type === 'tracks' ? (
            <div style={{ marginBottom: 12 }}>
              <TrackItem
                key={`most-popular-track`}
                track={searchResults.mostPopular.item}
                onClick={() => props.onClickTrack(searchResults.mostPopular?.item as TrackItemDefaultProps['track'])}
                onVoteClick={
                  props.onClickTrackVote
                    ? () => {
                        props.onClickTrackVote?.(searchResults.mostPopular?.item as TrackItemDefaultProps['track']);
                      }
                    : undefined
                }
              />
            </div>
          ) : searchResults.mostPopular?.type === 'artists' ? (
            <div style={{ width: 150 }}>
              <StyledIonNavLink
                key={`most-popular-artist`}
                routerDirection="forward"
                component={() => <ArtistDrawer artistId={searchResults.mostPopular?.item.id as number} />}
              >
                <ArtistCard artist={searchResults.mostPopular.item} onClick={() => {}} />
              </StyledIonNavLink>
            </div>
          ) : searchResults.mostPopular?.type === 'albums' ? (
            <div style={{ width: 150 }}>
              <StyledIonNavLink
                key={`most-popular-album`}
                routerDirection="forward"
                component={() => <AlbumDrawer albumId={searchResults.mostPopular?.item.id as string} />}
              >
                <AlbumCard album={searchResults.mostPopular.item} onClick={() => {}} />
              </StyledIonNavLink>
            </div>
          ) : null}
        </List>
        {props.adminMode ? (
          <>
            {props.loading ? (
              <div style={{ marginBottom: 12 }}>
                <List
                  header={
                    <Typography tag="h3" loading>
                      {t('mySpots')}
                    </Typography>
                  }
                >
                  <InfiniteVirtualScroll
                    key="spots-loading-elements"
                    loading
                    nbItemsPerView={nbItemsPerViewMostPopular}
                    spinningElement={<SpotCard loading />}
                  />
                </List>
              </div>
            ) : searchResults.spots.length > 0 && playSpotFeatureAvailable ? (
              <div style={{ marginBottom: 12 }}>
                <List header={t('mySpots')} onHeaderClick={props.onClickSpots}>
                  <InfiniteVirtualScroll
                    loadData={props.loadDataSpots}
                    fetching={props.isSpotsFetching}
                    nbItemsPerView={nbItemsPerViewMostPopular / 1.1}
                    spinningElement={<SpotCard loading />}
                  >
                    {searchResults.spots.map((spot) => {
                      return (
                        <SpotCard
                          key={`spot-${spot.id}`}
                          loading={false}
                          spot={spot}
                          onClick={() => {
                            props.onClickSpotCard(spot);
                          }}
                        />
                      );
                    })}
                  </InfiniteVirtualScroll>
                </List>
              </div>
            ) : null}
            {props.loading ? (
              <div style={{ marginBottom: 12 }}>
                <List
                  header={
                    <Typography tag="h3" loading>
                      {t('myPlaylists')}
                    </Typography>
                  }
                >
                  <InfiniteVirtualScroll
                    loading
                    nbItemsPerView={nbItemsPerViewMostPopular}
                    spinningElement={<PlaylistCard loading />}
                  />
                </List>
              </div>
            ) : searchResults.playlists.length > 0 ? (
              <div style={{ marginBottom: 12 }}>
                <List header={t('myPlaylists')} onHeaderClick={props.onClickPlaylists}>
                  <InfiniteVirtualScroll
                    loadData={props.loadDataPlaylists}
                    fetching={props.loading || props.isPlaylistsFetching}
                    nbItemsPerView={nbItemsPerViewMostPopular}
                    spinningElement={<PlaylistCard loading />}
                  >
                    {props.searchResults?.playlists.map((playlist, i) => (
                      <StyledIonNavLink
                        key={`playlist-${playlist.id}-${i}`}
                        routerDirection="forward"
                        component={() => (
                          <PlaylistDrawer
                            playlistId={playlist.id}
                            ambianceColor={playlist.ambiance?.color}
                            independentPlaylist={playlist.independent}
                          />
                        )}
                      >
                        <PlaylistCard playlist={playlist} onClick={() => {}} />
                      </StyledIonNavLink>
                    ))}
                  </InfiniteVirtualScroll>
                </List>
              </div>
            ) : null}
          </>
        ) : null}
        {props.adminMode ? (
          <>
            {props.loading ? (
              <div style={{ marginBottom: 12 }}>
                <List
                  header={
                    <Typography tag="h3" loading>
                      {t('ambiances')}
                    </Typography>
                  }
                >
                  <InfiniteVirtualScroll
                    loading
                    nbItemsPerView={nbItemsPerViewMostPopular}
                    spinningElement={<AmbianceCard loading />}
                  />
                </List>
              </div>
            ) : searchResults.ambiances.length > 0 ? (
              <div style={{ marginBottom: 12 }}>
                <List header={t('ambiances')} onHeaderClick={props.onClickAmbiances}>
                  <InfiniteVirtualScroll
                    fetching={props.loading || props.isAmbiancesFetching}
                    nbItemsPerView={nbItemsPerViewMostPopular}
                    spinningElement={<AmbianceCard loading />}
                  >
                    {props.searchResults?.ambiances.map((ambiance, i) => {
                      return (
                        <StyledIonNavLink
                          key={`ambiance-${ambiance.id}-${i}`}
                          routerDirection="forward"
                          component={() => <AmbianceDrawer ambianceId={ambiance.id} color={ambiance.color} />}
                        >
                          <AmbianceCard ambiance={ambiance} onClick={() => {}} />
                        </StyledIonNavLink>
                      );
                    })}
                  </InfiniteVirtualScroll>
                </List>
              </div>
            ) : null}
          </>
        ) : null}
        {props.loading ? (
          <div style={{ marginBottom: 12 }}>
            <List
              header={
                <Typography tag="h3" loading>
                  {t('tracks')}
                </Typography>
              }
            >
              {Array.from({ length: 2 }).map((track, i) => {
                return <TrackItem key={`loading-track-${i}`} loading />;
              })}
            </List>
          </div>
        ) : searchResults.tracks.length > 0 ? (
          <div style={{ marginBottom: 12 }}>
            <List header={t('tracks')} onHeaderClick={props.onClickTracks}>
              {searchResults?.tracks
                .filter((track, i) => i < 2)
                .map((track, i) => {
                  return (
                    <TrackItem
                      key={`track-${track.title}-${i}`}
                      track={track}
                      onClick={() => props.onClickTrack(track)}
                      onVoteClick={
                        props.onClickTrackVote
                          ? () => {
                              props.onClickTrackVote?.(track);
                            }
                          : undefined
                      }
                    />
                  );
                })}
            </List>
          </div>
        ) : null}
        {props.loading ? (
          <List
            header={
              <Typography tag="h3" loading>
                {t('artists')}
              </Typography>
            }
          >
            <InfiniteVirtualScroll
              loading
              nbItemsPerView={nbItemsPerViewMostPopular}
              spinningElement={<ArtistCard loading />}
            />
          </List>
        ) : searchResults.artists.length > 0 ? (
          <List header={t('artists')} onHeaderClick={props.onClickArtists}>
            <InfiniteVirtualScroll
              loadData={props.loadDataArtists}
              fetching={props.loading || props.isArtistsFetching}
              nbItemsPerView={nbItemsPerViewMostPopular}
              spinningElement={<ArtistCard loading />}
            >
              {searchResults?.artists.map((artist, i) => {
                return (
                  <StyledIonNavLink
                    key={`artist-${artist.id}-${i}`}
                    routerDirection="forward"
                    component={() => <ArtistDrawer artistId={artist.id} />}
                  >
                    <ArtistCard artist={artist} onClick={() => {}} />
                  </StyledIonNavLink>
                );
              })}
            </InfiniteVirtualScroll>
          </List>
        ) : null}
        {props.loading ? (
          <List
            header={
              <Typography tag="h3" loading>
                {t('albums')}
              </Typography>
            }
          >
            <InfiniteVirtualScroll
              loading
              nbItemsPerView={nbItemsPerViewMostPopular}
              spinningElement={<AlbumCard loading />}
            />
          </List>
        ) : searchResults.albums.length > 0 ? (
          <List header={t('albums')} onHeaderClick={props.onClickAlbums}>
            <InfiniteVirtualScroll
              loadData={props.loadDataAlbums}
              fetching={props.loading || props.isAlbumsFetching}
              nbItemsPerView={nbItemsPerViewMostPopular}
              spinningElement={<AlbumCard loading />}
            >
              {searchResults?.albums.map((album, i) => {
                return (
                  <StyledIonNavLink
                    key={`album-${album.title}-${i}`}
                    routerDirection="forward"
                    component={() => <AlbumDrawer albumId={album.id} />}
                  >
                    <AlbumCard album={album} onClick={() => {}} />
                  </StyledIonNavLink>
                );
              })}
            </InfiniteVirtualScroll>
          </List>
        ) : null}
      </div>
    </IonContent>
  ) : null;
};

export default SearchTabMostPopular;
