import React from 'react';
import { IonItem, IonLabel, IonList, IonRippleEffect } from '@ionic/react';
import { StyledIonList, StyledIonListHeader } from './List.styled';

type IonListProps = React.ComponentProps<typeof IonList>;
export type ListProps = IonListProps & {
  children?: React.ReactNode;
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  onHeaderClick?: () => void;
  noIcon?: boolean;
};

const List = (props: ListProps) => {
  const { children, header, subHeader, onHeaderClick, ...rest } = props;
  return (
    <>
      {(props.header || props.subHeader) && (
        <StyledIonListHeader>
          <IonItem
            className={`header-item ${props.noIcon ? 'no-icon' : ''}`}
            {...(props.onHeaderClick
              ? {
                  button: true,
                  onClick: props.onHeaderClick,
                }
              : {})}
            {...(props.noIcon ? { detailIcon: undefined } : {})}
          >
            <IonRippleEffect />
            <IonLabel className="header-label">
              <div>{props.header}</div>
              {props.subHeader && <div className="sub-header-label">{props.subHeader}</div>}
            </IonLabel>
          </IonItem>
        </StyledIonListHeader>
      )}
      <StyledIonList className="list" {...rest}>
        {props.children}
      </StyledIonList>
    </>
  );
};

export default List;
