import React from 'react';
import { IonContent, IonNavLink } from '@ionic/react';
import {
  StyledPageContent,
  StyledHeaderContainer,
  StyledPlaylistTitleContainer,
  StyledIonHeader,
  StyledLeftHeader,
  StyledIonContent,
} from './PlaylistLayout.styled';
import { TrackItemDefaultProps } from '../../../components/TrackItem';
import Typography from '../../../components/Typography';
import IconButton from '../../../components/IconButton';
import PlayButton from '../../../components/PlayButton';
import { chevronBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { secondsToTimeString } from '../../../utils/time';
import TrackList from '../../../components/TrackList/TrackList';

export type PlaylistLayoutDefaultProps = {
  loading?: boolean;
  playlist: {
    id: string;
    title: string;
    duration: number;
    tracks: TrackItemDefaultProps['track'][];
    creationDate: Date;
    source: string;
    color?: string;
    independent?: boolean;
  };
  onPlayClick?: () => void;
  onClickTrack: (track: TrackItemDefaultProps['track']) => void;
  onClickTrackVote?: (track: TrackItemDefaultProps['track']) => void;
};

type PlaylistLayoutLoadingProps = {
  loading: true;
  playlist?: {
    color?: string;
    independent?: boolean;
  };
};

export type PlaylistLayoutProps = PlaylistLayoutDefaultProps | PlaylistLayoutLoadingProps;

const PlaylistLayout = (props: PlaylistLayoutProps) => {
  const { t } = useTranslation();
  return (
    <StyledIonContent bgcolor={props.playlist?.color} independent={props.playlist?.independent}>
      <StyledPageContent>
        <StyledHeaderContainer>
          <StyledIonHeader>
            <StyledLeftHeader>
              <IonNavLink routerDirection="back">
                <IconButton icon={chevronBack} size="large" />
              </IonNavLink>
              <StyledPlaylistTitleContainer>
                {props.loading ? (
                  <Typography tag="h2" loading />
                ) : (
                  <Typography tag="h2">{props.playlist.title}</Typography>
                )}
                {props.loading ? (
                  <Typography tag="h5" variant="secondary" loading />
                ) : (
                  <Typography tag="h5" variant="secondary">
                    {props.playlist.source}
                  </Typography>
                )}
                {props.loading ? (
                  <Typography tag="h5" variant="secondary" loading />
                ) : (
                  <Typography tag="h5" variant="secondary">
                    {`${format(props.playlist.creationDate, 'dd/MM/yyyy')} • ${props.playlist.tracks.length} ${t(
                      'tracks',
                    )} • ${secondsToTimeString(props.playlist.duration / 1000)}`}
                  </Typography>
                )}
              </StyledPlaylistTitleContainer>
            </StyledLeftHeader>
            <PlayButton onClick={props.loading ? undefined : props.onPlayClick} loading={props.loading} />
          </StyledIonHeader>
        </StyledHeaderContainer>
        <IonContent>
          <TrackList
            tracks={
              props.loading
                ? Array.from({ length: 50 }).map(() => ({ loading: true }))
                : props.playlist.tracks.map((tr) => ({
                    track: tr,
                    onClick: () => {
                      props.onClickTrack(tr);
                    },
                    ...(props.onClickTrackVote && {
                      onVoteClick: () => {
                        props.onClickTrackVote?.(tr);
                      },
                    }),
                  }))
            }
          />
        </IonContent>
      </StyledPageContent>
    </StyledIonContent>
  );
};

export default PlaylistLayout;
