import { useEffect, useCallback, useState } from 'react';
import io from 'socket.io-client';
import App from './App';
import { App as CapacitorApp } from '@capacitor/app';
import { useLazyGetBlacklistsQuery, useGetBoxWithDomainQuery, useGetUserBoxesQuery } from './services/api.service';
import { getJwt, getUniqueUserUUID, getUserDecodedJwt, jwtNeedRefresh, setJwt, userIsLogged } from './utils/security';
import { useDispatch, useSelector } from 'react-redux';
import { initSocket, killSocket, getSocket } from './services/socket';
import { RootState } from './store/rootReducer';
import { useRefreshJwtQuery } from './services/auth.service';
import { useHistory } from 'react-router-dom';
import LoadingScreenLayout from './layouts/LoadingScreenLayout';
import ServerOfflineLayout from './layouts/ServerOfflineLayout';
import { useTranslation } from 'react-i18next';
import { clearCache } from './features/Search/utils';
import { setToast } from './store/toastSlice';
import useInterval from './utils/hooks/useInterval';
import { HeaderWithSwitchBox } from './features/components/HeaderWithSwitchBox';
import { resetAppState, setBoxDetail, setIsAdminMode, setIsSwitchingBox, setUsername } from './store/appSlice';
import { api } from './services/api.service';

const AppLoader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const decodedJWT = getUserDecodedJwt();
  const [fetchBlacklists] = useLazyGetBlacklistsQuery();
  const history = useHistory();
  const [isSocketError, setIsSocketError] = useState(false);
  const appState = useSelector((state: RootState) => state.AppReducer);
  const playerStateParamsLoaded = useSelector((state: RootState) => state.PlayerReducer.params.loaded);
  useRefreshJwtQuery({ boxId: decodedJWT?.currentBox.id }, { skip: !userIsLogged() || !jwtNeedRefresh() });
  const { currentData: boxes, isError: getUserBoxesError, refetch: refetchGetUserBoxesQuery } = useGetUserBoxesQuery();
  const foundBox = decodedJWT ? boxes?.find((b) => b.id === decodedJWT?.currentBox.id) : undefined;
  const selectedBox = foundBox || boxes?.[0];
  const {
    data: clientWithDom,
    isError: getBoxDomainError,
    isFetching: getBoxDomainFetching,
    refetch: refetchGetBoxWithDomainQuery,
  } = useGetBoxWithDomainQuery(selectedBox?.domain || '', {
    skip: !selectedBox?.domain,
  });

  if (!userIsLogged()) {
    history.push('/login');
  }
  const boxDetailResult = clientWithDom && clientWithDom.success && clientWithDom.result;

  useEffect(() => {
    (async () => {
      if (appState.boxDetail?._id) {
        await fetchBlacklists(appState.boxDetail?._id);
        clearCache();
      }
    })();
  }, [appState.boxDetail?._id, fetchBlacklists]);

  useEffect(() => {
    const logout = () => {
      dispatch(api.util.resetApiState());
      dispatch(resetAppState());
      setJwt(undefined);
      history.push('/login');
    };
    window.addEventListener('performLogout', logout);
    return () => {
      window.removeEventListener('performLogout', logout);
    };
  }, [appState, dispatch, history]);

  const connectToSocket = useCallback((boxId: string, socketConf) => {
    if (boxId) {
      localStorage.setItem('lastStreamId', boxId);
      const protocol = process.env.REACT_APP_DEVELOPER_MODE
        ? +process.env.REACT_APP_DEVELOPER_MODE === 1
          ? 'ws'
          : 'wss'
        : 'wss';
      const socket = io(`${protocol}://` + socketConf.host, {
        path: `/${socketConf.path}`,
        query: { username: socketConf.name, token: getJwt() },
        reconnection: false,
      });
      initSocket(socket, boxId);
      socket.on('connect_error', (error: Error) => {
        console.error('socket connect_error', error);
        setIsSocketError(true);
      });
      socket.on('connect', () => {
        setIsSocketError(false);
      });
      socket.on('disconnect', (error: Error) => {
        console.error('disconnect', error);
        connectToSocket(boxId, socketConf);
      });
    }
  }, []);

  const initApp = useCallback(() => {
    if (boxDetailResult && boxDetailResult._id !== appState.boxDetail?._id) {
      if (appState.isSwitchingBox) {
        dispatch(
          setToast({
            isOpen: true,
            header: t(''),
            message: t('boxChanged'),
            duration: 5000,
          }),
        );
      }
      dispatch(setIsSwitchingBox(false));
      dispatch(setBoxDetail(boxDetailResult));
      dispatch(setUsername((decodedJWT && decodedJWT.name) || undefined));
      dispatch(setIsAdminMode(boxDetailResult._id === decodedJWT?.currentBox.id && decodedJWT?.currentBox.admin));
      connectToSocket(boxDetailResult._id, {
        host: boxDetailResult.socket.host,
        path: boxDetailResult.socket.path,
        name: (decodedJWT && decodedJWT.name) || getUniqueUserUUID(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxDetailResult]);

  useEffect(() => {
    (async () => {
      await initApp();
    })();
    return () => {
      killSocket();
    };
  }, [initApp]);

  useEffect(() => {
    CapacitorApp.addListener('appStateChange', (state) => {
      if (appState.boxDetail && state.isActive) {
        connectToSocket(appState.boxDetail._id, {
          host: appState.boxDetail.socket.host,
          path: appState.boxDetail.socket.path,
          name: (decodedJWT && decodedJWT.name) || getUniqueUserUUID(),
        });
      }
    });
    return () => {
      CapacitorApp.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectToSocket, appState.boxDetail]);

  useInterval(() => {
    if (getUserBoxesError) {
      refetchGetUserBoxesQuery();
      return;
    }
    if (getBoxDomainError) {
      refetchGetBoxWithDomainQuery();
      return;
    }
    if (isSocketError || !getSocket().connected) {
      if (appState.boxDetail) {
        connectToSocket(appState.boxDetail._id, {
          host: appState.boxDetail.socket.host,
          path: appState.boxDetail.socket.path,
          name: (decodedJWT && decodedJWT.name) || getUniqueUserUUID(),
        });
      }
      return;
    }
  }, 2000);
  const somethingWentWrong = getUserBoxesError || (clientWithDom && !clientWithDom.success) || getBoxDomainError;
  const loading = !playerStateParamsLoaded || getBoxDomainFetching || isSocketError;

  return !jwtNeedRefresh() && somethingWentWrong ? (
    <ServerOfflineLayout
      renderHeader={() => <HeaderWithSwitchBox title={appState.boxDetail?.name || ''} subtitle={t('serverOffline')} />}
    />
  ) : loading ? (
    <LoadingScreenLayout />
  ) : (
    <App />
  );
};

export default AppLoader;
