const memoizedTypographiesClientRect: Record<string, DOMRect> = {};

export const getTypographyBoundingClientRect = (tag?: string, text?: string) => {
  const selectedTag = tag || 'p';
  const selectedText = text || 'Lorem Ipsum';
  const existingValue = memoizedTypographiesClientRect[`${selectedTag}|${selectedText}`];
  if (existingValue) {
    return existingValue;
  }
  const el = document.createElement(selectedTag);
  el.textContent = selectedText;
  el.style.display = 'inline-block';
  el.style.margin = '0';
  document.body.appendChild(el);
  const lorem = el.getBoundingClientRect();
  el.remove();
  memoizedTypographiesClientRect[`${selectedTag}|${selectedText}`] = lorem;
  return lorem;
};
